import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import { smallTablet } from 'helper/constants/mediaRules';
import { FONT_SIZE_16, MEDIUM_STONE } from 'helper/constants/styles';
import { userGradleDistibutionURL } from '../state/user';

const Content = styled.div`
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 5rem;
  table, tbody, tr, td {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  th {
    text-align: left;
  }
  td {
    overflow: auto;
    &:last-child {
      margin-top: 1rem;
      background-color: ${MEDIUM_STONE};
      padding: 1rem;
    }
    &:last-child:empty::after {
      content: 'No description';
    }
  }
  tr {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
  }
  thead {
    display: none;
  }
  code {
    font-family: 'Courier New', Courier, monospace !important;
    border-radius: 0 !important;
  }
  br {
    height: 10px;
    content: '';
    display: block;
  }
  h1 {
    font-size: 1.4em;
    ${smallTablet(css`
      font-size: 2em;
    `)}
  }
  h2 {
    font-weight: 700;
  }
  h3 {
    border-bottom: 1px solid rgba(255,255,255,0.5);
    padding-bottom: 10px;
    &:not(:first-child) {
      padding-top: 1.45rem;
    }
  }
  p {
    font-size: ${FONT_SIZE_16};
    margin-bottom: 2.6rem;
    ${smallTablet(css`
      font-size: 1.125em;
      margin-bottom: 1.25rem;
    `)}
  }
`;

const DocsTemplate = ({
  data: {
    markdownRemark: { html },
  },
  gradleDistibutionURL,
}) => (
  <Content
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: html.replace('DISTRIBUTION_URL', gradleDistibutionURL) }}
  />
);

export default connect(
  ({ user }) => ({ gradleDistibutionURL: userGradleDistibutionURL(user) }),
)(DocsTemplate);

// looks at the current path and finds the markdown node that has a matching slug
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
    }
  }
`;
